// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/InfoPanel.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/InfoPanel.tsx");
  import.meta.hot.lastModified = "1732006320682.162";
}
// REMIX HMR END

import { cn } from "~/utils/cn";
import { LinkButton } from "./Buttons";
import { Header2 } from "./Headers";
import { Paragraph } from "./Paragraph";
const variants = {
  info: {
    panelStyle: "border-grid-bright bg-background-bright rounded-md border p-4 gap-3"
  },
  upgrade: {
    panelStyle: "border-indigo-400/20 bg-indigo-800/10 rounded-md border p-4 gap-3"
  },
  minimal: {
    panelStyle: "max-w-full w-full py-3 px-3 gap-2"
  }
};
export function InfoPanel({
  title,
  children,
  to,
  buttonLabel,
  icon,
  iconClassName,
  variant = "info",
  panelClassName = "max-w-sm"
}) {
  const Icon = icon;
  const variantStyle = variants[variant];
  return <div className={cn(variantStyle.panelStyle, title ? "flex-col" : "", "flex h-fit items-start", panelClassName)}>
      <div className={cn("flex items-center gap-2", to ? "w-full justify-between" : "")}>
        <Icon className={cn("size-5", iconClassName)} />

        {to && <LinkButton to={to} variant="secondary/small">
            {buttonLabel}
          </LinkButton>}
      </div>
      <div className="flex flex-col gap-1">
        {title && <Header2 className="text-text-bright">{title}</Header2>}
        {typeof children === "string" ? <Paragraph variant={"small"} className="text-text-dimmed">
            {children}
          </Paragraph> : children}
      </div>
    </div>;
}
_c = InfoPanel;
var _c;
$RefreshReg$(_c, "InfoPanel");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;